.faq{
    display: flex;
    padding-right: 5rem;
    padding-left: 5rem;
    flex-direction: column;
    margin-bottom: 5rem;
}

.faq-heading{
    padding-bottom: 2rem;
    font-size: 2.25rem;
}

.faq .faq-services{
    display: flex;
    justify-content: space-between;  
}

.faq .faq-services h2{
    margin-bottom: 3rem;
    border-bottom: 3px solid rgba(128, 128, 128, 0.416);
    font-size: 1.5rem;
    min-height: 1.5rem;
}

.faq .faq-services h2:hover{
    color: #EE6225;
    border-bottom: 3px solid #EE6225;
    cursor: pointer;
}

.faq .faq-services h2.active {
    color: #EE6225;
    border-bottom: 3px solid #EE6225;
}

.questions {
    margin-top: 0.3rem;
}

.collapse-title{
    width: 100%;
    padding: 1rem;
    min-height: 3.75rem;
    position: relative;
    font-size:1.125rem ;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapse-title:hover {
    background-color: #f1f1f1;
}

.collapse-content{
    visibility: visible;
    min-height: fit-content;
    padding-left: 1rem;
    transition: padding .2s ease-out;
    transition: background-color .2s ease-out;
}

.content-none{
    max-height: 0;
    padding-left: 0;
    display: none;       
}

.collapse-content span{
    font-weight: 500;
}

.question{
    border-bottom: 2px solid rgba(128, 128, 128, 0.416) ;
}

.arrow-sign{
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
}

/* Media Query for max-width 480px */
@media (max-width: 480px) {
    .faq {
        padding: 1.0rem;
    }

    .faq-heading {
        font-size: 1.5rem;
    }

    .faq .faq-services {
        flex-direction: column;
        align-items: flex-start;
    }

    .faq .faq-services h2 {
        font-size: 1.0rem;
        text-align: left;
        margin: 0.5rem;
    }

    .collapse-title {
        font-size: 0.875rem;
    }

    .arrow-sign {
        font-size: 1.25rem;
    }
}