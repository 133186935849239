* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    width: 100%;
    height: 100%;
}

.hireChef {
    height: 35rem;
    width: 100vw;
    position: relative;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
}

.hireChefImage {
    width: calc(100vw - 10.8rem);/* Subtracting 10.8rem for 5.4rem margin on both sides */
    height: auto;
    position: absolute;
    top: 0;
    left: 5.4rem;
    z-index: -1;
    border-radius: 15px;
}

.hireChefContent {
    display: flex;
    position: relative;
    z-index: 1;
    color: black;
    padding: 8rem;
}

.hireChefContent button{
    width: 200px;
    padding: 12px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin-right: 1rem;
    transition: transform 0.10s ease-out;
    background-color: #b70014;
    color: white;
}

.hireChefContent button:hover{
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.09)0px 2px 1px, rgba(0, 0, 0, 0.09)0px 4px 2px, rgba(0, 0, 0, 0.09)0px 8px 4px, rgba(0, 0, 0, 0.09)0px 16px 8px, rgba(0, 0, 0, 0.09)0px 32px 16px;
    border: 1px solid white;
}

.hireChefContent p{
    width: 60%;
    font-size: 1.2rem;
    padding-bottom: 2rem;
}

.hireChefContent h2{
    color: #b70014;
    font-weight: 700;
    font-size: 2.4rem;
    padding-bottom: 2rem;
}

.subscribe{
    display: flex;
    justify-content: space-between;
    background-color: #e7ecf5;
    margin: 5rem;
    align-items: center;
    border-radius: 20px;
    padding: 2.5rem;
}

.subscribe img{
    width: 400px;
}

.subscribe h2{
    color: #eb1f00;
    font-size: 4rem;
    font-weight: 800;
    padding-bottom: 1rem;
}

.subscribe h5{
    font-size: 1.8rem;
}
.subscribe span{
    color: #eb1f00;
    font-size: 2rem;
}
