@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.welcomePage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcomePage .images{
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;      
}

.welcomePage .span1{
    font-weight: 700;
    font-style: italic;
    color: #323434;
}
.welcomePage .prep{
    font-weight: 800;
    color: rgb(171 0 0);
}
.welcomePage .eat{
    font-weight: 800;
    color: rgb(4 116 2);
}

.welcomePage h1{
    padding-bottom: 2rem;
    font-family: "Playfair Display SC";
    /* font-size: 3rem; */

    font-size: 2.5rem;
        /* Adjust font size for responsiveness */
        text-align: center;
        /* Center the text for smaller screens */
}

.welcomePage .images img{
    /* border-radius: 10px;
    width: 420px; 
    margin-bottom: 2rem;
    transition:transform 0.7s ease;
    display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        text-align: center;
        margin: 20px 0px;
        overflow: scroll; */
        border-radius: 10px;
            width: 100%;
            /* Make images responsive */
            max-width: 400px;
            /* Set a max width for larger screens */
            margin-bottom: 2rem;
            transition: transform 0.7s ease;
            overflow: scroll;
}


.welcomePage .images img:hover {
    transform: scale(1.05);
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
    .welcomePage h1 {
        font-size: 2rem;
        /* Smaller font size for tablets */
    }

    .welcomePage .images img {
        max-width: 300px;
        /* Adjust image size for tablets */
    }
}

@media (max-width: 480px) {
    .welcomePage h1 {
        font-size: 1.8rem;
        /* Smaller font size for mobile devices */
    }

    .welcomePage .images img {
        max-width: 100%;
        /* Images take up full width on small screens */
    }
}
