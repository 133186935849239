.contact-meal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    background-color: #8080801c;
    padding: 1rem;
    border-radius: 10px;
    font-size: 18px;
}

.time-loc{
    display: flex;
    gap: 1rem;
}

.mealType, .loc {
    align-items: center;
    padding: 0.7rem;
    border-radius: 10px;
    border: none;
    border: 2px solid #EE6225;
}

.contact{
    display: flex;
    gap: 1rem;
}

.cont {
    background-color: #EE6225;
    padding: 0.7rem;
    border-radius: 1rem;
    width: 11rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    border: none;
    color: white;
}

.btn1{
    background-color: #EE6225;
}

.btn2{
    background-color: rgb(76, 175, 80);
}

.cont:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease-out;
}

.cont .btnimg {
    padding-left: 1rem;
}

.cont span{
    padding-left: 0.4rem;
}

.meal{
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
    align-content: center;
    justify-content: center;
    margin-bottom: 4rem;
}

.mealtime{
    display: flex;
    background-color: #8080801c;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    margin-left: 5rem;
    margin-right: 5rem;
    border-radius: 15px;
}

.notAvailable{
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.notAvailable h2{
    font-size: 1.5rem;
    font-weight: 300;
}

.mealtime h2{
    font-weight: 500;
}