.chef-header {
    height: 45rem;
    margin-top: 5rem;
    background: url('../../../assets/chefServices/chef-header.jpeg') no-repeat center center;
    background-size: contain;
    background-position: center;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}