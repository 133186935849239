.loginPage{
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: space-between;
}


.login-sec1{
    margin-top: -6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 4rem;
}

.login-sec1 p{
    margin-top: -4rem;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    width: 70%;
}

.login-sec2{
    display: flex;
    flex-direction: column;   
    margin-right: 8rem;
}

.login-sec2 .login-form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.login-sec2 input{
    padding: 0.6rem;
    padding-left: 1rem;
    border-radius: 10px;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-size: 1.3rem;
    width: 25rem;
}

.login-sec2 .login-form .forget-pass{
    display: flex;
    justify-content: flex-end;
    color:#e77015 ;
    cursor: pointer;
}

.login-sec2 h2{
    margin-bottom: 2rem;
    text-align: center;
}

.login-sec2 button{
    padding: 10px 8px;
    border-radius: 10px;
    background-color: #e77015;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.3rem;
}

.login-sec2 .login-form .google-login-btn{
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 1.2rem;
}

.login-sec2 button:hover {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.login-sec2 .create-acc-p{
    text-align: center;
}

.login-sec2 .login-form span{
    color: #e77015;
    cursor: pointer;
}

.login-sec2 .google-icon{
    padding-bottom: 0.2rem;
}

.password-input-container {
    position: relative;
    width: 100%;
}

.password-input-container input {
    width: 100%;
    padding-right: 40px;
    /* Adjust to make room for the icon */
}

.eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
}