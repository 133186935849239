.nav{
    padding-bottom: 1px;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;       
}
.navContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 5;
    color: black;
    position: fixed;
}

.navContent {
    padding-left: 4rem;
    padding-right: 4rem;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    background: #f1f4f8;
}

.nav-menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 5rem;
    font-weight: 500;
}

.nav-menu li:hover{
color: #e77015;
cursor: pointer;
}

.btnn{
    width: 150px;
    padding: 13px 10px;
    border-radius: 25px;
    background-color: #e77015;
    color: white;
    border: none;
    cursor: pointer;
}

.btnn:hover{
margin-top: 7px;
}

