.tryApp{
    display: flex;
    padding-left: 5rem;
    background-color: #fff2e8 ;
    padding-right: 4rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 4rem;
    align-items: center;
    justify-content: center;
}

.tryApp .burger {
    width: 30%;
    height: 20%;
}

.tryApp h4{
    color:#fc8019 ;
    font-weight: 700;
    font-size: 2.25rem;
    padding-bottom: 1rem;
}

.tryApp p{
    color: #827E7E;
    padding-bottom: 2rem;
    width: 80%;
}

.tryApp h3{
    color: #424242;
    font-weight: 700;
    font-size: 1rem;
    padding-bottom: 1rem;
}

.tryApp span{
    color: #fc8019;
    padding-left: 1rem;
    font-weight: 500;
    font-size: 1.2rem;
    padding-right: 1rem;
}

.tryApp .content{
    padding-left: 2rem;
}

.tryApp .buttons{
    display: flex;
    gap: 1rem;
}

.tryApp .appbtn{
    background-color: white;
    padding: 0.8rem;
    border-radius: 1rem;
    width: 12rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
}

.tryApp .appbtn:hover{
    transform: scale(1.05);
    transition: transform 0.5s ease-out;
    border: 2px solid #EE6225;
}

.tryApp .btnimg{
    padding-left: 1rem;
}

@media (max-width: 480px) {

    .tryApp{
        margin: 0;
        padding: 0;
    }

    .tryApp h4 {
        padding-top: 2rem;
        padding-left: 1rem;
        padding-right: 3rem;
    }

    .tryApp h3{
        padding-right: 3rem;
    }

    .phone, .burger{
        display: none;
    }

    .content{
       text-align: center;
    }

    .content p{
        text-align: center;
        width: 100%;
        padding: 1rem;
        padding-right: 3rem;
    }

    .buttons{
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        align-items: center;
        padding-right: 3rem;
    }

    .tryApp .appbtn{
        width: 11.5rem;
    }

    .tryApp .appbtn:hover {
        transform: scale(1.0);
        transition: transform 0.5s ease-out;
        border: 2px solid #EE6225;
    }
}
