.header {
    height: 45rem; 
    margin-top: -25px; 
    background: url('../../../assets/welcomepgBanner.png') no-repeat center center;
    background-size: contain; 
    background-position: center;
    border-radius: 10px;
    position: relative;
    display: flex; 
    align-items: center; 
    justify-content: center; 

    padding: 20px;
        /* Add padding for small screens */
    box-sizing: border-box;
        /* Ensure padding doesn't affect overall width */
}

.banner {
    margin: 0;
    padding: 0;
}


.header-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5vw;
    text-align: center;
    /* max-width: 50%; */

    max-width: 90%;
        /* Adjust for responsiveness */
        width: 100%;
        /* Ensure full width for smaller screens */
}

.header-contents button{
    width: 200px;
    padding: 10px 8px;
    border-radius: 10px;
    background-color: #e77015;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

.header-contents button:hover{
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border: 1px solid white;
}

.header-contents h2 {
    font-size: 2.3rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 25px;
    margin: 0 auto;
    width: fit-content;
}

.header-contents .side{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
        /* Center align for smaller screens */
}

.header-contents input {
    border-radius: 10px;
    padding: 0.7rem;
    border: 1px solid #ccc;
    font-size: 1.2rem;
    box-sizing: border-box;
}



@media (max-width: 365px) {

    .header{
        background-size: cover;
        background-position: center;
    }
    .header-contents h2 {
        font-size: 1.3rem;
        padding: 6px 14px;
    }

    .header-contents input {
        font-size: 0.9rem;
        /* Adjust input field font size */
        max-width: 100%;
        /* Ensure input field covers full width */
    }

    .header-contents button {
        font-size: 15px;
        /* Adjust button font size */
        max-width: 100%;
        /* Ensure button covers full width */
    }
    
}