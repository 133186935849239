.tiffin-header-content{
    height: 45rem;
    margin-top: -25px;
    background: url('../../../assets/TiffinServices/tiffin-headerBg.png') no-repeat center center;
    background-size: contain;
    background-position: center;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coming-soonBanner{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
}

.coming-soonBanner h3{
    padding: 1rem;
    font-size: 1.25rem;
}

.tiffin-header-content img{
    padding-left: 7rem;
    padding-right: 7rem;
}

.tiffin-header-content .header-sec1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tiffin-header-content p{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    color: white;
    line-height: 1.75rem;
    padding: 0rem 9rem;
    padding-top: 1.75rem;
    text-align: center;
}

@keyframes zoomInOut {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

.tiffin-header-content .header-sec1 img {
    padding-left: 7rem;
    padding-right: 7rem;
    animation: zoomInOut 2s ease-in-out infinite;
}