.offers {
    height: 27rem;
    margin-top: -20px;
    background: url('../../../assets/TiffinServices/tiffin_Img.png') no-repeat center center;
    background-size: contain;
    background-position: center;
    position: relative;
    display: flex;
    margin: 0rem 5rem;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
}

.offers h2{
    color: rgb(255 61 0);
    font-size: 3rem;
    line-height: 1rem;
    font-weight: 800;
    padding-bottom: 2rem;
    padding-top: 5rem;
}

.offers span{
    font-size: 1.875rem;
}

.dis{
    color: rgb(255 61 0);
}

.timings{
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-top: 3rem;
}

.times{
    /* background-color: bisque; */
    border-radius: 18px;
    padding: 4rem;
    width: 26.3rem;
    height: 12.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.times:hover {
    transform: scale(1.05);
    transition-duration: .3s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.times h4{
    padding-top: 0.8rem;
}

.breakfast{
    background: linear-gradient(136.27deg, #63a13c, #63a13c14);
}

.lunch{
    background: linear-gradient(136.27deg, #ffd31a, #ffd31e2b);
}

.dinner{
    background: linear-gradient(136.27deg, #db2127, #db212714);
}