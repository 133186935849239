.availableOptions{
    display: flex;
    gap: 2rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.bookTiffin h1 {
    font-weight: 700;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-bottom: 3rem;
    padding-top: 3rem;
    font-size: 2.5rem;
}

.tiffinTime h4 {
    font: bold;
    padding-bottom: 1rem;
    margin-top: 1rem;
    color: #EE6225;
}

.tiffinTime {
    border-radius: 15px;
    width: 18rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    border: none;
    text-align: center;
    transition: transform 0.7s ease;
    margin-left: 1rem;
    margin-right: 1rem;
}

.tiffinTime:hover {
    transform: scale(1.05);
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.bookTiffin-body img{
    width: 100%;
}

.bookTiffin-body p{
    color: #000000;
}

