.features {
    display: flex;
    gap: 1.5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.prodFeatures h1{
    font-weight: 700;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-bottom: 2rem;
    padding-top: 3rem;
    font-size: 3rem;
}

.feat h4{
    font: bold;
    padding-bottom: 1rem;
    margin-top: 4rem;
}

.feat {
    border-radius: 15px;
    width: 18rem;
    background-color: #fff2e8;
    padding: 1rem;
    width: 20rem;
    margin-bottom: 4rem;
    border: none;
    transition: transform 0.7s ease;
}

.feat:hover{
    transform: scale(1.05);
    border: 2px solid #EE6225;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.arrow{
    display: none;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .prodFeatures h1 {
        font-size: 2rem;
        padding-top: 2rem;
    }

    .feat {
        margin-bottom: 2rem;
        max-width: 20rem;
    }

    .features {
        gap: 1rem;
    }
}

@media (max-width: 480px) {
    .prodFeatures h1 {
        font-size: 1.8rem;
        padding-top: 1.5rem;
    }

    .feat {
        margin-bottom: 1.5rem;
        max-width: 16rem;
        overflow: scroll;
    }

    .arrow{
        display: block;
    }

    .features {
        gap: 0.5rem;
    }
}


.prodFeatures {
    position: relative;
}

.features-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    /* Hide scrollbars */
}

.features {
    display: flex;
    overflow-x: auto;
    /* Enable horizontal scrolling */
    scroll-behavior: smooth;
    gap: 1rem;
    /* Space between items */
    scroll-snap-type: x mandatory;
    /* Optional: Snap scrolling */
}

.card.feat {
    min-width: 20rem;
    /* Adjust based on card size */
    flex: none;
    scroll-snap-align: center;
    /* Optional: Snap alignment */
}

.arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.arrow.left {
    left: 0;
}

.arrow.right {
    right: 0;
}

@media (max-width: 480px) {
    .features-container {
        flex-direction: row;
    }

    .arrow {
        display: block;
    }
}
