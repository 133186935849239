.ourWork{
    background-color: #8080801c;
    display: flex;
    margin: 5rem;
    border-radius: 15px;
}

.ourWork img{
    width: 400px;
    height: 100%;
}

.ourWork .work-content{
    display: flex;
    flex-direction: column;
    padding: 3rem;
   
}

.ourWork .work-content h2{
    padding-bottom: 2rem;
    color: #426e23;
    font-weight: 700;
    font-size: 2.2rem;
}

.ourWork .work-content p{
    font-size: 1.2rem;
    line-height: 2.5rem;
    padding-bottom: 1rem;
}

.work-content .strategy p{
    color: #426e23;
    font-size: 1.5rem;
}

.work-content .strategy li{
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
}

.work-content .strategy li:hover {
    font-size: 1.4rem;
}