@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: outfit;
  scroll-behavior: smooth;
}

body,
html {
  margin: 0;
  padding: 0;
}

ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}