.servRight{
    display: flex;
    border-radius: 2rem;
    padding-left: 2rem;
    margin: 6rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.servLeft{
    display: flex;
    border-radius: 2rem;
    margin: 6rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.servLeft .service-card{
    padding-left: 2rem;
}

.homemaker-img{
    border-radius: 2rem;
}

.services img{
    width: 58%;
}

.service-card{
    padding-top: 3rem;
}

.service-card h2{
    padding-bottom: 1rem;
}
.service-card p{
    width: 90%;
    padding-bottom: 3rem;
}

.sc1 h2{
    color: #E8750B;
}

.services p{
    color: #333333;
    font-size: 1.2rem;
}

.services a{
    width: 200px;
    padding: 12px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin-right: 1rem;
    transition: transform 0.10s ease-out;
}

.services a:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border: 1px solid white;
}

.sc1 .sc1-a1{
    background-color: #E8750B;
    color: white;
}
.sc1 .sc1-a2 {
    background-color: #fff2e8;
    color:#E8750B ;
}

.sc2 .sc2-a1{
    background-color: #596c32;
    color: white;
}
.sc2 .sc2-a2 {
    background-color: #f5f6f2;
    color:#596c32 ;
}
.sc2 h2{
    color: #596c32;
}

.sc3 .sc3-a1{
    background-color: #cf0623;
    color: white;
}
.sc3 .sc3-a2 {
    background-color: #f3c1c87c;
    color:#cf0623 ;
}
.sc3 h2 {
    color: #cf0623;
}

.sc4 .sc4-a1{
    background-color: #06666D;
    color: white;
}
.sc4 .sc4-a2 {
    background-color: #05535925;
    color:#06666D ;
}
.sc4 h2 {
    color: #06666D;
}

@media (max-width: 480px){
    .services{
        margin-left:-4rem;
        margin-right: -4rem;
    }

    .services img {
        width: 100px;
        height: 100px;
        display: none;
    }

    .service-card{
        height: 22rem;
        width: 100%;
    }

    .services p{
        font-size: 0.8rem;
        padding-bottom: 0;
    }

    .serv-button{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .services a {
        width: 160px;
        font-size: 16px;
        margin-right: 1rem;
        transition: transform 0.10s ease-out;
        text-align: center;
    }
}