.footer{
    display: flex;
    flex-direction: column;
    background-color: #f64e19;
}

.footer h3{
    font-size: 1.3rem;
}

.footer .sec1{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    padding: 5rem;
    color: white;
}

.footer .footerLeft li, h3 {
    padding-bottom: 0.9rem;
}

.footer .footerLeft li:hover{
    color:#1e1e1e;
    cursor: pointer;
}

.footer .footerRight li, h3 {
    padding-bottom: 0.9rem;
}

.footer-mid img{
    display: flex;
    width: 200px;
    flex-direction: column;
    margin: 1rem;
    justify-content: center;
    cursor: pointer;
}

.footer-mid img:hover{
box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
border: 1px solid white;
border-radius: 1rem;
}

.footer .sec2 {
    display: flex;
    background-color:#1e1e1e;
    margin-left: 4rem;
    margin-right: 4rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 2rem;
    padding-bottom: 1rem;
    justify-content: space-between;
}

.footer .sec2 p{
    color: white;
}

.sec2 .sec2-left img{
    width: 120px;
}

.sec2 .sec2-right img{
    width: 7%;
    margin: 0.5rem;
}

.foot-end img{
    display: none;
}

@media (max-width: 480px) {

    .footer .sec1 {
        display: flex;
        flex-direction: column;
        padding: 5rem;
        color: white;
        margin-top: -2rem;
        margin-left: -3rem;
    }
    
    .footerRight{
        margin-top: 2rem;
    }

    .footer-mid img{
        display:none;
        width: 150px;
    }

    .footer .sec2{
        margin-top: -5rem;
        background-color:#f64e19;
        display: flex;
        flex-direction: column;
    }

    .sec2 .sec2-right img{
        width: 16%;
    }
    

    .footer .sec2 p{
        width: 100%;
    }

        .foot-end{
            display: flex;
            margin: 1rem;
            justify-content: center;
            cursor: pointer;
            gap: 1rem;
        }

        .foot-end img{
            display: block;
            width: 130px;
        }

    
}